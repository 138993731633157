<template>
	<TnTransitionHeight :showIf="visible">
		<TnNotificationInline
			:type="notificationType"
			:orientation="orientation"
			:title="content.messageHeading"
			:closable="!component.content.hideCloseButton"
			@close="visible = false"
			:dark="dark"
			:hide-icon="notificationType === 'neutral'"
		>
			<template #default>
				<RichText
					:dark="dark"
					:size="size"
					:text="messageText"
					:editable="editable"
				/>
			</template>
			<template #actions>
				<TnLink
					v-if="link"
					:size="size"
					:dark="dark"
					v-bind="link"
				/>
			</template>
		</TnNotificationInline>
	</TnTransitionHeight>
</template>

<script>
import GizmoComponentMixin from "../../../../mixins/GizmoComponentMixin.js";
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "AlertMessage",
	mixins: [GizmoComponentMixin],

	data() {
		return {
			visible: true,
		};
	},

	computed: {
		notificationType() {
			if (this.content?.alertType) {
				// For backwards compatibility
				switch (this.content.alertType) {
					case "Info (blue)":
						return "neutral";
					case "Alert (red)":
					default:
						return "error";
				}
			}
			if (this.component?.content?.notificationType === "informational") {
				return "neutral";
			}
			return this.component?.content?.notificationType;
		},
		orientation() {
			return this.component?.content?.orientation || "horizontal-responsive";
		},
		size() {
			if (["s", "m", "l"].includes(this.component?.content?.size)) {
				return this.component.content.size;
			} else {
				return "m";
			}
		},
		link() {
			return this.content.link ? LinkViewModel(this.content.link) : undefined;
		},
		messageText() {
			return this.content.messageText ? this.content.messageText.replace("\n", "<br>") : undefined;
		},
	},
});
</script>
